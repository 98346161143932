import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import DownloadIcon from "@mui/icons-material/Download";
import "./Downloads.scss";


const BEGRIPPENLIJST_AUTO = "https://theorieja.nl/76spv1gJlip3Y30E.pdf";
const BEGRIPPENLIJST_MOTOR = "https://theorieja.nl/7gohbcgj0mpAXoyg.pdf";
const VERKEERSBORDEN = "https://theorieja.nl/23wwGe4WsqE6Qdqe.pdf";


const Downloads = () => {
    const { reduxUser } = useAuthContext();

    const downloadFile = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");

        aTag.href = url;
        aTag.setAttribute("download", fileName)
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    return (<>
        <div className="downloads container">
            <NavMenu />
            <Header page={"Bestanden"} />
            
            <main>
                <h1>Bestanden</h1>

                <div className="download-list">
                    <div
                        className="download"
                        onClick={() => {
                            downloadFile(reduxUser.huidigVoertuig === "Auto" ? BEGRIPPENLIJST_AUTO : (reduxUser.huidigVoertuig === "Motor" ? BEGRIPPENLIJST_MOTOR : ""));
                        }}
                    >
                        <DownloadIcon />
                        <div className="title">
                            <div className="text centered">Begrippenlijst</div>
                        </div>
                    </div>
                    <div
                        className="download"
                        onClick={() => {
                            downloadFile(VERKEERSBORDEN);
                        }}
                    >
                        <DownloadIcon />
                        <div className="title">
                            <div className="text centered">Verkeersborden</div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

        <Footer />
    </>);
};

export default Downloads;